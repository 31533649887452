import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Dr. Kaloti's Best-Selling book - The Smile Stylist" description="Call Now! (905) 849-0558 - Dr. Kaloti is one of the best doctors out there in Oakville" keywords="dr kaloti, dentist, book about teeth, dental implants, dental implants canada, dental clinics, oakville, toronto, mississauga, canada" />
    <main className="site-content" role="main">
    <section id="main-top-img" className="book hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h1>Dr. Kaloti's Best-Selling Book</h1>
            <h2>The 21 Principles of Smile Design</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="main-top-img" className="book hidden-sm hidden-md hidden-lg">
      <div className="container">
        <div className="row">
          <p>&nbsp;</p>
        </div>
      </div>
    </section>
    <section id="top-mobile" className="hidden-sm hidden-md hidden-lg">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h2>Dr. Kaloti's Best-Selling Book</h2>
            <h2>The 21 Principles of Smile Design</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInLeft">
            <p className="lead wow animated zoomIn" data-wow-delay="0.6s">The 21 Principles of Smile Design</p>
            <p className="csrr">COMING SOON</p>
          </div>
          <div className="col-sm-4 wow animated zoomIn text-center" data-wow-delay="0.6s">
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage